body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EDF5FF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.table_section tr th{
  background-color: #3465EA;
  color: #fff;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
}
.table_section table{
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
}
.table_section tr:nth-of-type(odd){
  background-color: #f5f5f5 !important;
}
.table_section tr:nth-of-type(odd) td{
  background-color: #f5f5f5 !important;
}
.table_section tr td{
  font-size: 16px;
  font-weight: 400;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  border-bottom: 1px solid #ccc !important;
}